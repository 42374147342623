import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box, Typography, Button, TextField, FormControl,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid
} from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, TimeScale);

const UserCountPage: React.FC = () => {
    const [count, setCount] = useState<number>(0);
    const [userTag, setUserTag] = useState<string>("All");
    const [rankingData, setRankingData] = useState<Array<{ user_id: string, rank: number, name: string, score: number }>>([]);
    const [weeklyRankingData, setWeeklyRankingData] = useState<Array<{ user_id: string, rank: number, name: string, score: number }>>([]);
    const [frequencyData, setFrequencyData] = useState<number[]>([]);
    const [histogramData, setHistogramData] = useState<{ labels: number[], frequencies: number[] }>({ labels: [], frequencies: [] });
    const [cumulativeUserData, setCumulativeUserData] = useState<{ dates: string[], cumulativeCounts: number[] }>({ dates: [], cumulativeCounts: [] });
    const [ageHistogramData, setAgeHistogramData] = useState<{ labels: string[], frequencies: number[] }>({ labels: [], frequencies: [] });
    const [hourHistogramData, setHourHistogramData] = useState<{ labels: string[], frequencies: number[] }>({ labels: [], frequencies: [] });
    const [activeIndexHistogramData, setActiveIndexHistogramData] = useState<{ labels: string[], frequencies: number[] }>({ labels: [], frequencies: [] });
    const [authTypeData, setAuthTypeData] = useState<{ auth_type: string, count: number }[]>([]);
    const [userId, setUserId] = useState<string>('');
    const [directCount, setDirectCount] = useState<number>(0);
    const [indirectCount, setIndirectCount] = useState<number>(0);
    const [deleteUserId, setDeleteUserId] = useState<string>('');

    const fetchData = async () => {
        try {
            const params: any = {};
            if (userTag !== 'All') {
                params.user_tag = userTag;
            }

            const countResponse = await axios.get('https://api.be-native.life/api/users/dev/count', { params });
            setCount(countResponse.data.count);

            const rankingResponse = await axios.get('https://api.be-native.life/api/users/rankings');
            const filteredRankingData = rankingResponse.data.filter((user: any) => user.input_name !== 'forbiz');
            setRankingData(filteredRankingData);

            const weeklyRankingResponse = await axios.get('https://api.be-native.life/api/users/dev/weekly_rankings');
            const filteredWeeklyRankingData = weeklyRankingResponse.data
                .filter((user: any) => user.input_name !== 'forbiz')
                .map((user: any, index: number) => ({
                    rank: index + 1,
                    user_id: user.user_id,
                    name: user.input_name,
                    score: user.other_users.length // assuming score is the number of friends
                }));
            setWeeklyRankingData(filteredWeeklyRankingData);

            const frequencyResponse = await axios.get('https://api.be-native.life/api/users/freq_other_users');
            setFrequencyData(frequencyResponse.data);

            const userResponse = await axios.get('https://api.be-native.life/api/users/');
            const filteredUserData = userResponse.data.filter((user: any) => user.input_name !== 'forbiz');
            processCumulativeUserData(filteredUserData);
            processAgeHistogramData(filteredUserData);
            processHourHistogramData(filteredUserData);
            processActiveIndexHistogramData(filteredUserData);

            await fetchAuthTypeData(); // Fetch auth type data
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const fetchAuthTypeData = async () => {
        try {
            const response = await axios.get('https://api.be-native.life/api/friends/auth_type_stats');
            setAuthTypeData(response.data);
        } catch (error) {
            console.error('Error fetching auth type data', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array to run only once on mount

    useEffect(() => {
        if (frequencyData.length > 0) {
            const frequencyMap = frequencyData.reduce((acc: { [key: number]: number }, curr) => {
                acc[curr] = (acc[curr] || 0) + 1;
                return acc;
            }, {});

            const labels = Object.keys(frequencyMap).map(Number);
            const frequencies: number[] = Object.values(frequencyMap).map(value => Number(value));

            setHistogramData({ labels, frequencies });
        }
    }, [frequencyData]);

    const processCumulativeUserData = (userData: Array<{ user_id: string, created_at: string, input_name: string }>) => {
        const filteredUserData = userData.filter(user => user.input_name !== 'forbiz');
        const dateCounts: { [date: string]: number } = {};

        filteredUserData.forEach(user => {
            const date = user.created_at.split(' ')[0];
            if (!dateCounts[date]) {
                dateCounts[date] = 0;
            }
            dateCounts[date]++;
        });

        const dates = Object.keys(dateCounts).sort();
        let cumulativeCount = 0;
        const cumulativeCounts = dates.map(date => {
            cumulativeCount += dateCounts[date];
            return cumulativeCount;
        });

        setCumulativeUserData({ dates, cumulativeCounts });
    };

    const processAgeHistogramData = (userData: Array<{ dob: string, input_name: string }>) => {
        const filteredUserData = userData.filter(user => user.input_name !== 'forbiz');
        const currentYear = new Date().getFullYear();
        const ageCounts: { [age: number]: number } = {};

        filteredUserData.forEach(user => {
            const birthYear = new Date(user.dob).getFullYear();
            const age = currentYear - birthYear;
            if (!ageCounts[age]) {
                ageCounts[age] = 0;
            }
            ageCounts[age]++;
        });

        const labels = Object.keys(ageCounts).map(Number).sort((a, b) => a - b);
        const frequencies = labels.map(age => ageCounts[age]);

        setAgeHistogramData({ labels: labels.map(String), frequencies });
    };

    const processHourHistogramData = (userData: Array<{ created_at: string, input_name: string }>) => {
        const filteredUserData = userData.filter(user => user.input_name !== 'forbiz');
        const hourCounts: { [hour: number]: number } = {};

        filteredUserData.forEach(user => {
            const hour = new Date(user.created_at).getHours();
            if (!hourCounts[hour]) {
                hourCounts[hour] = 0;
            }
            hourCounts[hour]++;
        });

        const labels = Array.from({ length: 24 }, (_, i) => i.toString());
        const frequencies = labels.map(hour => hourCounts[parseInt(hour)] || 0);

        setHourHistogramData({ labels, frequencies });
    };

    const processActiveIndexHistogramData = (userData: Array<{ created_at: string, updated_at: string, input_name: string }>) => {
        const filteredUserData = userData.filter(user => user.input_name !== 'forbiz');
        const activeIndexCounts: number[] = [];

        filteredUserData.forEach(user => {
            const createdAt = new Date(user.created_at);
            const updatedAt = new Date(user.updated_at);
            const differenceInHours = Math.abs(updatedAt.getTime() - createdAt.getTime()) / 36e5;
            const differenceInHoursRounded = Math.floor(differenceInHours);

            activeIndexCounts.push(differenceInHoursRounded);
        });

        const frequencyMap = activeIndexCounts.reduce((acc: { [key: number]: number }, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
        }, {});

        const labels = Object.keys(frequencyMap).map(hour => `${hour} - ${Number(hour) + 1}`);
        const frequencies: number[] = Object.values(frequencyMap).map(value => Number(value));

        setActiveIndexHistogramData({ labels, frequencies });
    };

    const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value);
    };

    const handleDeleteUser = async () => {
        const enteredUsername = prompt("Enter username:");
        const enteredPassword = prompt("Enter password:");

        if (!enteredUsername || !enteredPassword) {
            alert("Username and password are required!");
            return;
        }

        try {
            await axios.delete(`https://api.be-native.life/api/users/dev/${deleteUserId}`, {
                auth: {
                    username: enteredUsername,
                    password: enteredPassword
                }
            });
            alert(`User with ID ${deleteUserId} has been deleted.`);
        } catch (error) {
            console.error("Error deleting user", error);
            alert("Failed to delete user. Please check the user ID and credentials.");
        }
    };

    const handleCalculateClick = async () => {
        try {
            const response = await axios.get(`https://api.be-native.life/api/users/dev/connected_nodes_count/${userId}`);
            setDirectCount(response.data.direct_count);
            setIndirectCount(response.data.indirect_count);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const data = {
        labels: histogramData.labels,
        datasets: [
            {
                label: 'Frequency',
                data: histogramData.frequencies,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const cumulativeData = {
        labels: cumulativeUserData.dates,
        datasets: [
            {
                label: 'Cumulative User Count',
                data: cumulativeUserData.cumulativeCounts,
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
                fill: false,
            },
        ],
    };

    const ageData = {
        labels: ageHistogramData.labels,
        datasets: [
            {
                label: 'Age Frequency',
                data: ageHistogramData.frequencies,
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
            },
        ],
    };

    const hourData = {
        labels: hourHistogramData.labels,
        datasets: [
            {
                label: 'Hourly Frequency',
                data: hourHistogramData.frequencies,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    const activeIndexData = {
        labels: activeIndexHistogramData.labels,
        datasets: [
            {
                label: 'Active Index Frequency',
                data: activeIndexHistogramData.frequencies,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const authTypeChartData = {
        labels: authTypeData.map(item => item.auth_type),
        datasets: [
            {
                label: 'Auth Type Count',
                data: authTypeData.map(item => item.count),
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                borderWidth: 1,
            },
        ],
    };

    const incentiveData = {
        labels: ['Direct Count', 'Indirect Count'],
        datasets: [
            {
                label: 'Count',
                data: [directCount, indirectCount],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    const cumulativeOptions = {
        scales: {
            x: {
                type: 'time' as const,
                time: {
                    unit: 'day' as const,
                },
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Cumulative User Count',
                },
            },
        },
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="auto">
            <Typography variant="h1" marginBottom={4}>Num users: {count}</Typography>

            {/* Delete User Section */}
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={11}>
                    <Typography variant="h4" marginBottom={2}>ユーザーを削除</Typography>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" margin="normal">
                        <TextField
                            label="User ID"
                            value={deleteUserId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeleteUserId(e.target.value)}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={handleDeleteUser}>ユーザーを削除</Button>
                </Grid>
            </Grid>

            {/* Incentive Calculation Section */}
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={11}>
                    <Typography variant="h4" marginBottom={2}>インセンティブを計算</Typography>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" margin="normal">
                        <TextField
                            label="User ID"
                            value={userId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserId(e.target.value)}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleCalculateClick}>インセンティブを計算</Button>
                </Grid>
                <Grid item xs={10} sx={{ marginTop: 4, width: '80%' }}>
                    <Bar data={incentiveData} options={options} />
                </Grid>
            </Grid>

            {/* Other sections (Rankings, Histograms, etc.) */}
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Node Count Ranking</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rank</TableCell>
                                <TableCell>User ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Num friends</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rankingData.map((row) => (
                                <TableRow key={row.user_id}>
                                    <TableCell>{row.rank}</TableCell>
                                    <TableCell>{row.user_id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.score}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Weekly Node Count Ranking</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Rank</TableCell>
                                <TableCell>User ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Num friends</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {weeklyRankingData.map((row) => (
                                <TableRow key={row.user_id}>
                                    <TableCell>{row.rank}</TableCell>
                                    <TableCell>{row.user_id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.score}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Node Count Frequency</Typography>
                <Bar data={data} options={options} />
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Cumulative User Count</Typography>
                <Line data={cumulativeData} options={cumulativeOptions} />
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Age Frequency</Typography>
                <Bar data={ageData} options={options} />
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Hourly Frequency</Typography>
                <Bar data={hourData} options={options} />
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Active Index Histogram</Typography>
                <Bar data={activeIndexData} options={options} />
            </Box>
            <Box sx={{ marginTop: 4, width: '80%' }}>
                <Typography variant="h4" marginBottom={2}>Auth Type Statistics</Typography>
                <Bar data={authTypeChartData} options={options} />
            </Box>
        </Box>
    );
};

export default UserCountPage;
