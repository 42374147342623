import React from 'react';
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface User {
    user_id: string;
    created_at: string;
    input_name: string;
    dob: string;
    cognito_user_id: string;
    cognito_email_address: string;
    cognito_nickname: string;
    cognito_user_name: string;
}

const CsvDownloadPage: React.FC = () => {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [filter, setFilter] = useState('All');
    const [searchUserId, setSearchUserId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        let filtered = users;
        if (filter === 'Friends') {
            filtered = users.filter(user => user.input_name !== 'forbiz');
        } else if (filter === 'Biz') {
            filtered = users.filter(user => user.input_name === 'forbiz');
        }
        if (searchUserId) {
            filtered = filtered.filter(user => user.user_id.includes(searchUserId));
        }
        setFilteredUsers(filtered);
    }, [filter, users, searchUserId]);

    const handleFetchData = async () => {
        try {
            const response = await axios.get('https://api.be-native.life/api/users/dev/csv', {
                auth: {
                    username: userId,
                    password: password
                },
                responseType: 'text'
            });

            const data = response.data.split('\n').slice(1).map((row: string) => {
                const [user_id, created_at, input_name, dob, cognito_user_id, cognito_email_address, cognito_nickname, cognito_user_name] = row.split(',');
                return { user_id, created_at, input_name, dob, cognito_user_id, cognito_email_address, cognito_nickname, cognito_user_name };
            }).filter((user: User) => user.cognito_user_id);

            setUsers(data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const handleDownloadCsv = () => {
        const csvContent = `card_id,カード発行日時,任意の入力名,生年月日,Auth_user_id,Email address,Auth name,Auth user\n` +
            users.map(user =>
                `${user.user_id},${user.created_at},${user.input_name},${user.dob},${user.cognito_user_id},${user.cognito_email_address},${user.cognito_nickname},${user.cognito_user_name}`
            ).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'users.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleNavigateToGraph = () => {
        navigate('/graph');
    };

    const handleNavigateToUserCount = () => {
        navigate('/users/count');
    };

    return (
        <div>
            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" wrap="nowrap" sx={{ marginTop: '20px' }}>
                <Grid item>
                    <TextField
                        label="User ID"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        variant="outlined"
                        sx={{ height: 56, '.MuiInputBase-input': { height: 'auto' } }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        sx={{ height: 56, '.MuiInputBase-input': { height: 'auto' } }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFetchData}
                        sx={{ height: 56 }}
                    >
                        データを取得
                    </Button>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" sx={{ minWidth: 120, height: 56 }}>
                        <InputLabel>Filter</InputLabel>
                        <Select
                            value={filter}
                            onChange={(e) => setFilter(e.target.value as string)}
                            label="Filter"
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Friends">Friends</MenuItem>
                            <MenuItem value="Biz">Biz</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField
                        label="Search by User ID"
                        value={searchUserId}
                        onChange={(e) => setSearchUserId(e.target.value)}
                        variant="outlined"
                        sx={{ height: 56, '.MuiInputBase-input': { height: 'auto' } }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDownloadCsv}
                        disabled={users.length === 0}
                        startIcon={<GetAppIcon />}
                        sx={{ height: 56 }}
                    >
                        CSVエクスポート
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={handleNavigateToGraph}
                        sx={{ height: 56 }}
                    >
                        グラフを描画
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleNavigateToUserCount}
                        sx={{ height: 56 }}
                    >
                        ユーザーカウント
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>card_id</TableCell>
                            <TableCell>カード発行日時</TableCell>
                            <TableCell>任意の入力名</TableCell>
                            <TableCell>生年月日</TableCell>
                            <TableCell>Auth_user_id</TableCell>
                            <TableCell>Email address</TableCell>
                            <TableCell>Auth user</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell>{user.user_id}</TableCell>
                                <TableCell>{user.created_at}</TableCell>
                                <TableCell>{user.input_name}</TableCell>
                                <TableCell>{user.dob}</TableCell>
                                <TableCell>{user.cognito_user_id}</TableCell>
                                <TableCell>{user.cognito_email_address}</TableCell>
                                <TableCell>{user.cognito_user_name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default CsvDownloadPage;
