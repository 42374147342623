import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CsvDownloadPage from './Users';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import GraphComponent from './Graph';
import UserCountPage from './Userscount';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Stack spacing={2}>
          <AppBar position="static" sx={{ height: '64px', backgroundColor: '#000' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography variant="h4">
                Ec2-hiro Console
              </Typography>
            </Box>
          </AppBar>
          <Routes>
            <Route path="/" element={<CsvDownloadPage />} />
            <Route path="/graph" element={<GraphComponent />} />
            <Route path="/users/count" element={<UserCountPage />} />
          </Routes>
        </Stack>
      </div>
    </BrowserRouter>
  );
}

export default App;